// This entry point takes care of rendering the registration and sign in forms
// on Devise routes that need them.

import React from 'react'
import ReactDOM from 'react-dom'

import ready from './static/lib/ready'
import InlineSignInNav from './components/inline_sign_in_nav'
import { initializeTelemetry } from './lib/telemetry'
import { reportingEnvironment } from './lib/settings'

ready(() => {
  initializeTelemetry({ reportingEnvironment: reportingEnvironment() })

  const sessionRoot = document.getElementById('react-session-root')

  if (!sessionRoot) return

  const props = JSON.parse(sessionRoot.getAttribute('data-props'))

  ReactDOM.render(<InlineSignInNav {...props} />, sessionRoot)
})
